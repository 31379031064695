import { Container } from '@jsluna/react'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import {
  clearLocalStorePlanners,
  setLocalStorePlanners,
} from '../../../utils/localPlanner'
import { getUserStore } from '../../../utils/localStore'
import { getUserPreferredPlanner } from '../../api/plannerApi'
import { getStoreCounters } from '../../api/storeApi'
import { useApiClient } from '../../AppContext/appContext'
import { PreferredPlanner } from '../../enums/PreferredPlannerEnum'
import { IStorePlanners } from '../../types/IStorePanners'
import Loading from '../Loading'
import BakeryIcon from './PlannerIcons/Bread.svg'
import HotFoodIcon from './PlannerIcons/HotFood.svg'
import PizzaIcon from './PlannerIcons/Pizza.svg'
import PlannerSelectionCard from './PlannerSelectionCard'
import ProductionPlanningHeader from './ProductionPlanningHeader'

const PlannerSelection = (props: RouteComponentProps) => {
  const [loading, setShowLoading] = useState(true)
  const [error, setShowError] = useState(false)
  const [storePlanners, setStorePlanners] = useState<IStorePlanners>({
    count: 0,
    hasBakery: false,
    hasHotFood: false,
    hasPizza: false,
  })

  const apiClient = useApiClient()
  const queryParams = props.location.search

  const sendToUsersPlanner = (
    preferredPlanner: number,
    planners: IStorePlanners,
  ) => {
    if (planners.count === 0) {
      clearLocalStorePlanners()
      setShowError(true)
      return
    }

    // back to planner selection screen if preferred planner is not available from a selected store
    if (
      (preferredPlanner === (PreferredPlanner.Bakery as number) &&
        !planners.hasBakery) ||
      (preferredPlanner === (PreferredPlanner.Pizza as number) &&
        !planners.hasPizza) ||
      (preferredPlanner === (PreferredPlanner.HotFood as number) &&
        !planners.hasHotFood) ||
      queryParams === '?reset=true'
    ) {
      preferredPlanner = PreferredPlanner.None
    }

    switch (preferredPlanner) {
      case PreferredPlanner.None:
        if (planners.count > 1) {
          return
        }
        if (planners.hasBakery) {
          props.history.push('/bakeryview')
        } else {
          if (planners.hasPizza) {
            props.history.push('/pizzaview')
          } else {
            props.history.push('/hotfoodview')
          }
        }
        break

      case PreferredPlanner.Bakery:
        props.history.push('/bakeryview')
        break

      case PreferredPlanner.Pizza:
        props.history.push('/pizzaview')
        break

      case PreferredPlanner.HotFood:
        props.history.push('/hotfoodview')
        break

      default:
        setShowError(true)
        break
    }
  }

  useEffect(() => {
    const storeId = getUserStore().storeId
    const preferredPlanner = getUserPreferredPlanner(apiClient)
    const storeCounters = getStoreCounters(apiClient, storeId)

    Promise.all([preferredPlanner, storeCounters])
      .then((results) => {
        // updated planners based on the StorePlanners
        const planners = {
          count:
            (results[1].hasBakery ? 1 : 0) +
            (results[1].hasPizza ? 1 : 0) +
            (results[1].hasHotFood ? 1 : 0),
          hasBakery: results[1].hasBakery,
          hasHotFood: results[1].hasHotFood,
          hasPizza: results[1].hasPizza,
        }

        setStorePlanners(planners) // used in this component (in the return statement)
        setLocalStorePlanners(planners) // Used by the header

        sendToUsersPlanner(results[0], planners)
      })
      .catch((e) => {
        let exceptionMessage = (e as Error).message
        if(exceptionMessage === 'maintenance') {
          window.location.href = `/service-unavailable?message=${exceptionMessage}`;
        }
        setShowError(true)
      })
      .finally(() => setShowLoading(false))
  }, [apiClient])

  const goError = () => props.history.push('/error')

  return (
    <>
      <ProductionPlanningHeader headerMessage={'Production Planning'} />

      {error && goError()}

      {loading ? (
        <Loading />
      ) : (
        <>
          <h2 className='ln-u-text-align-center ln-u-margin-top*4'>
            Choose your planner
          </h2>
          <Container soft className='ln-u-push-top o-common-loading-overlay'>
            {storePlanners.hasBakery && (
              <PlannerSelectionCard
                {...{
                  disabled: false,
                  linkPath: '/bakeryview',
                  plannerIcon: BakeryIcon,
                  plannerName: 'Bakery Planner',
                  plannerType: PreferredPlanner.Bakery,
                }}
              />
            )}
            {storePlanners.hasPizza && (
              <PlannerSelectionCard
                {...{
                  disabled: false,
                  linkPath: '/pizzaview',
                  plannerIcon: PizzaIcon,
                  plannerName: 'Pizza Planner',
                  plannerType: PreferredPlanner.Pizza,
                }}
              />
            )}
            {storePlanners.hasHotFood && (
              <PlannerSelectionCard
                {...{
                  disabled: false,
                  linkPath: '/hotfoodview',
                  plannerIcon: HotFoodIcon,
                  plannerName: 'Hot Food Temp Control',
                  plannerType: PreferredPlanner.HotFood,
                }}
              />
            )}
          </Container>
        </>
      )}
    </>
  )
}

export default PlannerSelection
