import { ConnectedRouter } from 'connected-react-router'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ForecastView from '../../bakery/components/Forecast/ForecastView'
import GOLView from '../../bakery/components/GOL/GOLView'
import BakeryIngredientView from '../../bakery/components/Ingredient/IngredientView'
import MixSkusView from '../../bakery/components/Production/MixSkusView'
import BakeryProductionView from '../../bakery/components/Production/ProductionView'
import QpigListView from '../../bakery/components/QPIG/QpigListView'
import { history } from '../../bakery/store/configureStore'
import HotFoodCounterTemperatureView from '../../hotfood/components/CounterTemperature/CounterTemperatureView'
import HotFoodDisplayView from '../../hotfood/components/Display/DisplayView'
import HotFoodPizzaPlacementView from '../../hotfood/components/Display/PizzaPlacement'
import HotFoodBatchView from '../../hotfood/components/Production/BatchView'
import HotFoodProductionView from '../../hotfood/components/Production/ProductionView'
import HotFoodReportView from '../../hotfood/components/Report/ReportView'
import PizzaForecastView from '../../pizza/components/Forecast/ForecastView'
import PizzaIngredientView from '../../pizza/components/Ingredient/IngredientView'
import PizzaProductionView from '../../pizza/components/Production/ProductionView'
import PizzaQpigListView from '../../pizza/components/QPIG/QpigListView'
import ErrorPage from './ErrorPage'
import NotFoundPage from './NotFoundPage'
import PlannerSelection from './PlannerSelection/PlannerSelection'
import QpigView from './QPIG/QpigView'
import StoreSetting from './StoreSetting'
import MaintenancePage from './MaintenancePage'

const Navigation = () => (
  <ConnectedRouter history={history}>
    <div>
      <Switch>
        {/* Common routes */}
        <Route exact path='/' component={StoreSetting} />
        <Route exact path='/storesetting' component={StoreSetting} />
        <Route exact path='/plannerselection' component={PlannerSelection} />
        <Route exact path='/qpig/:id' component={QpigView} />
        <Route exact path='/error' component={ErrorPage} />
        <Route exact path='/service-unavailable' component={MaintenancePage} />

        {/* Bakery routes */}
        <Route exact path='/bakeryview' component={BakeryProductionView} />
        <Route exact path='/bakeryforecast' component={ForecastView} />
        <Route exact path='/bakeryingredients' component={BakeryIngredientView} />
        <Route exact path='/mixskusview/:id/:date/:preview' component={MixSkusView}
        />
        <Route exact path='/qpiglistview' component={QpigListView} />
        <Route exact path='/golview' component={GOLView} />

        {/* Pizza routes */}
        <Route exact path='/pizzaview' component={PizzaProductionView} />
        <Route exact path='/pizzaforecast' component={PizzaForecastView} />
        <Route exact path='/pizzaingredients' component={PizzaIngredientView} />
        <Route exact path='/pizzaqpiglistview' component={PizzaQpigListView} />

        {/* Hot food routes */}
        <Route exact path='/hotfoodview' component={HotFoodProductionView} />
        <Route exact path='/hotfoodDisplay' component={HotFoodDisplayView} />
        <Route exact path='/hotfoodbatch/:id' component={HotFoodBatchView} />
        <Route exact path='/hotfoodbatch' component={HotFoodBatchView} />
        <Route exact path='/hotfoodpizza' component={HotFoodPizzaPlacementView} />
        <Route exact path='/hotfoodreport' component={HotFoodReportView} />
        <Route exact path='/hotfoodcountertemperature' component={HotFoodCounterTemperatureView} />

        {/* Unspecified paths */}
        <Route path='*' component={NotFoundPage} />
      </Switch>
    </div>
  </ConnectedRouter>
)

export default Navigation
