import { Container } from '@jsluna/react'
import React, { useContext, useEffect, useState } from 'react'

import { useApiClient } from '../../../common/AppContext/appContext'
import ErrorRefresh from '../../../common/components/ErrorRefresh'
import Header from '../../../common/components/Header'
import Loading from '../../../common/components/Loading'
import TutorialWrapper from '../../../common/components/TutorialWrapper'
import { setPlanner } from '../../../common/Context/commonDispatch'
import { Context } from '../../../common/Context/context'
import { setIngredients } from '../../../common/Context/ingredientDispatch'
import { ErrorMessage } from '../../../common/enums/ErrorMessage'
import {
  GetHeaderMainMenus,
  headerMainMenus,
  headerPlannerTitles,
} from '../../../common/enums/HeaderItems'
import { Menu } from '../../../common/enums/MenuEnum'
import { PlannerName } from '../../../common/enums/PlannerNameEnum'
import { getUserStore } from '../../../utils/localStore'
import { getIngredients } from '../../api/ingredientsApi'
import IngredientTable from './IngredientTable'

const IngredientView = () => {
  const { state, dispatch } = useContext(Context)
  const [tutorialToken, setTutorialToken] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [headerNavigationItems, setHeaderNavigationItems] = useState(
    headerMainMenus.pizzaNavs,
  )

  const apiClient = useApiClient()

  useEffect(() => {
    GetHeaderMainMenus(
      apiClient,
      getUserStore().storeId,
      PlannerName.Pizza,
    ).then((res) => setHeaderNavigationItems(res))
  }, [])

  useEffect(() => {
    dispatch(setPlanner(PlannerName.Pizza))

    apiClient
      .getTutorialAccessToken()
      .then((res) => setTutorialToken(res))

      .catch((e) => console.log(e as Error))

    getIngredients(apiClient, getUserStore().storeCode)
      .then((res) => {
        if (res.length > 0) {
          dispatch(setIngredients(res))
        } else {
          setErrorMessage(ErrorMessage.IngredientsView.FailedToLoad)
        }
      })
      .catch((e) => {
        let exceptionMessage = (e as Error).message
        if(exceptionMessage === 'maintenance') {
          window.location.href = `/service-unavailable?message=${exceptionMessage}`;
        }
        setErrorMessage(ErrorMessage.IngredientsView.FailedToLoad)
      })
      .finally(() => setIsLoading(false))
  }, [dispatch, apiClient])

  return (
    <>
      {!tutorialToken ? (
        <Loading message='Tutorial Loading' />
      ) : (
        <TutorialWrapper state={state} token={tutorialToken}>
          <Header
            title={headerPlannerTitles.pizza}
            navItems={headerNavigationItems}
            activeMenuType={Menu.Ingredient}
          />
          <Container soft className='ln-u-push-top-sm'>
            <div className='c-common-main-view-content'>
              <div className='ln-u-margin-top*4 ln-u-margin-bottom*4'>
                You'll need these minimum ingredients for standard pizzas
                (excluding 'create your own')
              </div>
              <div>
                <IngredientTable />
              </div>
            </div>

            {isLoading && <Loading />}
            {errorMessage && <ErrorRefresh message={errorMessage} />}
          </Container>
        </TutorialWrapper>
      )}
    </>
  )
}

export default IngredientView
