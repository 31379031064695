import { ErrorCircle } from '@jsluna/icons'
import { Container } from '@jsluna/react'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useApiClient } from '../../../common/AppContext/appContext'
import BannerHolder from '../../../common/components/Banners/BannerHolder'
import ErrorBanner from '../../../common/components/Banners/ErrorBanner'
import ErrorRefresh from '../../../common/components/ErrorRefresh'
import Header from '../../../common/components/Header'
import Loading from '../../../common/components/Loading'
import TutorialWrapper from '../../../common/components/TutorialWrapper'
import {
  addBannerMessage,
  setBannerMessages,
  setPlanner,
} from '../../../common/Context/commonDispatch'
import { Context } from '../../../common/Context/context'
import { ErrorMessage } from '../../../common/enums/ErrorMessage'
import {
  GetHeaderMainMenus,
  headerMainMenus,
  HeaderNavItem,
  headerPlannerTitles,
} from '../../../common/enums/HeaderItems'
import { Menu } from '../../../common/enums/MenuEnum'
import { PlannerName } from '../../../common/enums/PlannerNameEnum'
import { IResult } from '../../../common/types/IResult'
import { toNthDayOfWeekFullDatetimeString } from '../../../common/utils/times'
import { IStackBanner } from '../../../hotfood/types/IStackBanner'
import { getUserStore } from '../../../utils/localStore'
import { getPicklist, updatePicklist } from '../../api/picklistApi'
import { IPicklist } from '../../types/IPicklist'
import { IPicklistItem } from '../../types/IPicklistItem'
import { IPicklistPlannerGroup } from '../../types/IPicklistPlannerGroup'
import GOLPlannerGroupTable from './GOLPlannerGroupTable'

const GOLView = () => {
  const { dispatch, state } = useContext(Context)
  const [tutorialToken, setTutorialToken] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [errorMessage, setErrorMessage] = useState('')
  const [pickListPlannerGroups, setPickListPlannerGroups] = useState<
    IPicklistPlannerGroup[]
  >([])
  const [headerNavigationItems, setHeaderNavigationItems] = useState(
    headerMainMenus.bakeryNavs,
  )

  const apiClient = useApiClient()
  const pickListDate = useMemo(() => new Date(), [])
  const store = getUserStore()

  const updateLocalPickList = useCallback(() => {
    getPicklist(apiClient, pickListDate)
      .then((response: IPicklist) => {
        setPickListPlannerGroups(response.plannerGroups)
      })
      .catch((e: unknown) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log(e)
        }
        let exceptionMessage = (e as Error).message
        if(exceptionMessage === 'maintenance') {
          window.location.href = `/service-unavailable?message=${exceptionMessage}`;
        }
        setErrorMessage(`Error loading GOL List - ${(e as Error).message}`)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [apiClient, pickListDate])

  useEffect(() => {
    setIsLoading(true)

    dispatch(setPlanner(PlannerName.Bakery))

    const getHeaderMainMenusTask = GetHeaderMainMenus(
      apiClient,
      store.storeId,
      PlannerName.Bakery,
    )
    const getTutorialTokenTask = apiClient.getTutorialAccessToken()

    // Do not change the order of the promises in the Promise.all
    // Once the default tutorial not related with a feature is rendered,
    // it will not re-render again so all set states should be done before tutorial token is set
    Promise.all([getHeaderMainMenusTask, getTutorialTokenTask])
      .then((responses: [HeaderNavItem[], string | null]) => {
        setHeaderNavigationItems(responses[0])
        setTutorialToken(responses[1])
      })
      .catch((e) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log(e)
        }
        let exceptionMessage = (e as Error).message
        if(exceptionMessage === 'maintenance') {
          window.location.href = `/service-unavailable?message=${exceptionMessage}`;
        }
        setErrorMessage(
          `${ErrorMessage.GOLView.FailedToLoad} - ${(e as Error).message}`,
        )
      })
      .finally(() => {
        setIsLoading(false)
      })

    updateLocalPickList()
  }, [apiClient, dispatch, updateLocalPickList, store.storeId])

  const saveBanners = (banners: IStackBanner[]) => {
    dispatch(setBannerMessages(banners))
  }

  const handleSkuCheckChanged = (pickListItem: IPicklistItem): void => {
    pickListItem.completed = !pickListItem.completed
    const clone = pickListPlannerGroups.map((x) => ({ ...x }))
    setPickListPlannerGroups(clone)
    updatePicklist(apiClient, {
      completed: pickListItem.completed,
      orderDate: pickListDate,
      sainId: pickListItem.sainId,
      storeId: store.storeId,
    })
      .then((response: IResult) => {
        if (response.isSuccess === false) {
          dispatch(
            addBannerMessage(
              <ErrorBanner
                message={'Error updating GOL List'}
                icon={<ErrorCircle />}
              />,
            ),
          )

          updateLocalPickList()
        }
      })
      .catch((e: unknown) => {
        if (process.env.NODE_ENV !== 'production') {
          console.log(e)
        }
        dispatch(
          addBannerMessage(
            <ErrorBanner
              message={'Error updating GOL List'}
              icon={<ErrorCircle />}
            />,
          ),
        )

        updateLocalPickList()
      })
  }

  const sumQuantityInPlannerGroups = (
    plannerGroups: IPicklistPlannerGroup[],
  ): number => {
    let sum = 0
    plannerGroups.forEach((plannerGroup) => {
      plannerGroup.items.forEach((item) => {
        sum += item.quantity
      })
    })
    return sum
  }

  return (
    <>
      {!tutorialToken ? (
        <Loading message='Tutorial Loading' />
      ) : (
        <TutorialWrapper state={state} token={tutorialToken || ''}>
          <div className='c-common-main-view-content'>
            <Header
              title={headerPlannerTitles.bakery}
              navItems={headerNavigationItems}
              activeMenuType={Menu.GOL}
            />
            <Container soft className='ln-u-push-top-sm'>
              {isLoading && <Loading />}

              {!isLoading && errorMessage && (
                <ErrorRefresh message={errorMessage} />
              )}

              {!isLoading && !errorMessage && (
                <>
                  <BannerHolder
                    banners={state.bannerMessages}
                    setBanners={saveBanners}
                  />

                  <h4>GOL Picklist</h4>
                  <div>{toNthDayOfWeekFullDatetimeString()}</div>
                  <div className='ln-u-text-align-right c-gol-col-total'>
                    Total units:{' '}
                    {sumQuantityInPlannerGroups(pickListPlannerGroups)}
                  </div>

                  {pickListPlannerGroups
                    .sort(
                      (a, b) =>
                        a.plannerGroupPositionOnOutput -
                        b.plannerGroupPositionOnOutput,
                    )
                    .map((plannerGroup: IPicklistPlannerGroup) => (
                      <GOLPlannerGroupTable
                        plannerGroupName={plannerGroup.plannerGroupName}
                        pickList={plannerGroup.items}
                        handleSkuCheckChange={handleSkuCheckChanged}
                      ></GOLPlannerGroupTable>
                    ))}
                </>
              )}
            </Container>
          </div>
        </TutorialWrapper>
      )}
    </>
  )
}

export default GOLView
