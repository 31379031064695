import 'react-dates/initialize'

import {
  Card,
  Container,
  Link,
  SingleDatePicker,
  TabLink,
  TabPanel,
  Tabs,
} from '@jsluna/react'
import { CalendarBlank } from '@sainsburys-tech/icons'
import moment, { Moment, MomentInput } from 'moment'
import React, { useContext, useEffect, useState } from 'react'

import { isFeatureEnabled } from '../../../common/api/featureApi'
import { useApiClient } from '../../../common/AppContext/appContext'
import ErrorRefresh from '../../../common/components/ErrorRefresh'
import Header from '../../../common/components/Header'
import Loading from '../../../common/components/Loading'
import TutorialWrapper from '../../../common/components/TutorialWrapper'
import { setPlanner } from '../../../common/Context/commonDispatch'
import { Context } from '../../../common/Context/context'
import { ErrorMessage } from '../../../common/enums/ErrorMessage'
import { Feature } from '../../../common/enums/FeatureEnum'
import {
  GetHeaderMainMenus,
  headerMainMenus,
  headerPlannerTitles,
} from '../../../common/enums/HeaderItems'
import { Menu } from '../../../common/enums/MenuEnum'
import { PlannerName } from '../../../common/enums/PlannerNameEnum'
import { getUserStore } from '../../../utils/localStore'
import { getCounterAvailability } from '../../api/productionApi'
import {
  getCounterTemperatures,
  getTemperatureChecks,
} from '../../api/reportApi'
import { IBatchTemperaturesResponse } from '../../types/IBatchTemperaturesResponse'
import { ICounterAvailability } from '../../types/ICounterAvailability'
import { ICounterTemperaturesResponse } from '../../types/ICounterTemperaturesResponse'
import CounterView from './CounterView'
import TemperatureCheckView from './TemperatureCheckView'

moment.locale('en-GB')

const ReportView = () => {
  const { dispatch, state } = useContext(Context)
  const [tutorialToken, setTutorialToken] = useState<string | null>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [activeTab, setActiveTab] = React.useState('temperature-check')
  const [firstFocus, setFirstFocus] = useState(false)
  const [focused, setFocused] = useState(false)
  const [batchDate, setBatchDate] = useState<MomentInput | null>(
    moment(new Date(), 'DD/MM/YYYY', true),
  )
  const [batchTemperatures, setBatchTemperatures] =
    useState<IBatchTemperaturesResponse | null>(null)
  const [counterAvailability, setCounterAvailability] = useState<
    ICounterAvailability | undefined
  >(undefined)
  const [counterTemperatures, setCounterTemperatures] =
    useState<ICounterTemperaturesResponse | null>(null)
  const [isForecastingEnabled, setIsForecastingEnabled] = useState<
    boolean | null
  >(null)
  const [isHotFoodProbeFeature, setIsHotFoodProbeFeature] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [headerNavigationItems, setHeaderNavigationItems] = useState(
    headerMainMenus.hotfoodcounterNavs,
  )

  const apiClient = useApiClient()
  const tabs = [
    { name: 'Temp check reports', key: 'temperature-check' },
    { name: 'Counter reports', key: 'counter' },
  ]

  useEffect(() => {
    GetHeaderMainMenus(
      apiClient,
      getUserStore().storeId,
      PlannerName.HotFood,
    ).then((res) => setHeaderNavigationItems(res))
  }, [])

  useEffect(() => {
    dispatch(setPlanner(PlannerName.HotFood))
    if (!batchDate) {
      return
    }

    setIsLoading(true)
    const storeId = getUserStore().storeId

    const getTemperatureChecksTask = getTemperatureChecks(
      apiClient,
      storeId,
      moment(batchDate).toDate(),
    )
    const getCounterAvailabilityTask = getCounterAvailability(
      apiClient,
      storeId,
      moment(batchDate).toDate(),
    )
    const getCounterTemperaturesTask = getCounterTemperatures(
      apiClient,
      storeId,
      moment(batchDate).toDate(),
    )
    const getForecastingTask = isFeatureEnabled(
      apiClient,
      Feature.HotFoodBatches,
      storeId,
    )
    const getHotfoodProbFeatureTask = isFeatureEnabled(
      apiClient,
      Feature.HotFoodProbe,
      storeId,
    )
    const getTutorialTokenTask = apiClient.getTutorialAccessToken()

    Promise.all([
      getTemperatureChecksTask,
      getCounterAvailabilityTask,
      getCounterTemperaturesTask,
      getForecastingTask,
      getHotfoodProbFeatureTask,
      getTutorialTokenTask,
    ])
      .then(
        (
          responses: [
            IBatchTemperaturesResponse,
            ICounterAvailability,
            ICounterTemperaturesResponse,
            boolean,
            boolean,
            string | null,
          ],
        ) => {
          setBatchTemperatures(responses[0])
          setCounterAvailability(responses[1])
          setCounterTemperatures(responses[2])
          setIsForecastingEnabled(responses[3])
          setIsHotFoodProbeFeature(responses[4])
          setTutorialToken(responses[5])
        },
      )
      .catch((e: Error) => {
        let exceptionMessage = (e as Error).message
        if(exceptionMessage === 'maintenance') {
          window.location.href = `/service-unavailable?message=${exceptionMessage}`;
        }
        setErrorMessage(
          `${ErrorMessage.ReportView.FailedToLoad} - ${e.message}`,
        )
      })
      .finally(() => setIsLoading(false))
  }, [batchDate, apiClient, dispatch])

  const setDateFromPicker = (date: MomentInput | null) => {
    setBatchDate(date)
  }

  const dateTomorrow = () => {
    const dt = new Date(new Date().toDateString())
    dt.setDate(dt.getDate() + 1)
    return dt
  }

  const dateLastMonth = () => {
    const dt = new Date(new Date().toDateString())
    dt.setDate(dt.getDate() - 28)
    return dt
  }

  const hasSingleCabinetType = () => {
    if (
      counterTemperatures === null ||
      counterTemperatures.counterTemperatures?.length === 0
    ) {
      return undefined
    }

    const numberOfTurboServeCabinet =
      counterTemperatures.counterTemperatures?.filter(
        (cabinet) => cabinet.turboServe,
      ).length

    return (
      numberOfTurboServeCabinet === 0 ||
      numberOfTurboServeCabinet ===
        counterTemperatures.counterTemperatures?.length
    )
  }

  const handleFocusChange = () => {
    if (!firstFocus) {
      setFirstFocus(true)
      setShowDatePicker(false)
    }
    return !isLoading && setFocused(!focused)
  }

  const renderPage = () => (
    <div className='c-common-main-view-content'>
      <div className='c-common-hide-in-print'>
        <Header
          title={headerPlannerTitles.hotfood}
          navItems={headerNavigationItems}
          activeMenuType={Menu.Report}
        />
      </div>
      <Container soft className='ln-u-push-top-sm'>
        {errorMessage && <ErrorRefresh message={errorMessage} />}

        {!errorMessage && (
          <>
            <div className='ln-u-margin-bottom*2 c-common-hide-in-print'></div>
            <h4 className='c-common-hide-in-print'>
              Temperature check reports
            </h4>
            <p className='c-common-hide-in-print'>
              View checks from the past 4 weeks
            </p>

            {/* <Tabs type="fill"> */}
            <Tabs className='c-common-hide-in-print'>
              {tabs.map((tab) => (
                <TabLink
                  key={tab.key}
                  onClick={() => setActiveTab(tab.key)}
                  active={activeTab === tab.key}
                >
                  {tab.name}
                </TabLink>
              ))}
            </Tabs>
            <TabPanel>
              <div className='ln-u-margin-bottom c-common-hide-in-print'>
                <Card className='c-hotfood-report-date-picker'>
                  <div>
                    <span className='ln-u-font-weight-bold ln-u-margin-right'>
                      Summary from the {moment(batchDate).format('DD/MM/YYYY')}
                    </span>
                    <span className='c-hotfood-report-color-orange'>
                      <CalendarBlank className='c-hotfood-report-calendar-icon' />
                      <Link
                        className='ln-u-text-decoration-underline c-common-hover-over-cursor'
                        onClick={() => {
                          setShowDatePicker(true)
                          setFocused(true)
                        }}
                      >
                        Change date
                      </Link>
                    </span>
                  </div>

                  {showDatePicker && (
                    <SingleDatePicker
                      date={batchDate}
                      displayFormat='DD/MM/YYYY'
                      placeholder={
                        batchDate === null
                          ? 'Date'
                          : moment(batchDate).format('DD/MM/YYYY')
                      }
                      focused={focused}
                      onDateChange={setDateFromPicker}
                      onFocusChange={() => handleFocusChange()}
                      readOnly={true}
                      isOutsideRange={(day: Moment) =>
                        day.isBefore(moment(dateLastMonth())) ||
                        day.isAfter(moment(dateTomorrow()))
                      }
                    />
                  )}
                </Card>
              </div>

              {isLoading && <Loading />}

              {!isLoading &&
                activeTab === 'temperature-check' &&
                batchDate !== null && (
                  <TemperatureCheckView
                    batchDate={batchDate}
                    batchTemperatures={batchTemperatures!}
                    counterAvailability={counterAvailability}
                    hasSingleCabinetType={hasSingleCabinetType()}
                    isHotFoodProbeFeature={isHotFoodProbeFeature}
                  />
                )}

              {!isLoading && activeTab === 'counter' && batchDate !== null && (
                <CounterView
                  batchDate={batchDate}
                  counterTemperatureResponse={counterTemperatures!}
                  isHotFoodProbeFeature={isHotFoodProbeFeature}
                />
              )}
            </TabPanel>
          </>
        )}
      </Container>
    </div>
  )

  // do not render TutorialWrapper if in test mode.  N.b. tutorialToken only exists if not in test mode...
  return (
    <>
      {!tutorialToken ? (
        <Loading message='Tutorial Loading' />
      ) : (
        <TutorialWrapper
          state={state}
          token={tutorialToken || ''}
          enabledFeatures={{ forecasting: isForecastingEnabled || false }}
        >
          {renderPage()}
        </TutorialWrapper>
      )}
    </>
  )
}

export default ReportView
